<template>
  <div v-if="permission.access">
    <b-row class="justify-content-between mb-2">
      <b-col
        cols="12"
        md="4"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label>Show</label>
        <v-select
          @on-change="paginateChange"
          :options="perPageOptions"
          v-model="search.paginate"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>entries</label>
      </b-col>

      <!-- Search -->
      <b-col cols="12" md="4">
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="search.search_data"
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
          <b-dropdown
            text="Action"
            variant="outline-primary"
            :disabled="!select.selected"
          >
            <b-dropdown-item @click="Delete"> Delete</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>

    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-12">
            <b-card no-body>
              <div class="m-2">
                <b-row>
                  <b-col md="3">
                    <Select
                      v-model="search.is_verified"
                      filterable
                      @on-change="SearchData"
                      :clearable="true"
                      name="is_verified"
                      id="is_verified"
                      placeholder="Is Verified"
                    >
                      <Option :value="1">Complete</Option>
                      <Option :value="2">Not Complete</Option>
                    </Select>
                  </b-col>
                  <b-col md="3">
                    <Select
                      v-model="search.course_id"
                      filterable
                      @on-change="SearchData"
                      :clearable="true"
                      name="course_id"
                      id="course_id"
                      placeholder="Select Purchase Coruse"
                    >
                      <Option value="no-purchase">No Purchase Coruse</Option>
                      <Option value="purchase">Purchase Coruse</Option>
                      <Option
                        :value="course.id"
                        v-for="(course, index) in courses"
                        :key="index"
                        v-if="courses.length"
                        >{{ course.name }}
                      </Option>
                    </Select>
                  </b-col>
                  <b-col md="3">
                    <DatePicker
                      format="yyyy-MM-dd"
                      type="date"
                      name="from_date"
                      v-model="search.from_date"
                      id="from_date"
                      placeholder="Select From Date"
                      :clearable="true"
                      style="width: 100%"
                    ></DatePicker>
                  </b-col>
                  <b-col md="3">
                    <DatePicker
                      format="yyyy-MM-dd"
                      type="date"
                      name="to_date"
                      v-model="search.to_date"
                      id="to_date"
                      placeholder="Select To Date"
                      :clearable="true"
                      style="width: 100%"
                    ></DatePicker>
                  </b-col>
                </b-row>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="align-middle text-center">Sl</th>
                      <th>Image</th>
                      <th>Student Info</th>
                      <th class="text-center">Status</th>
                      <th>Timestamp</th>
                      <th class="align-middle">
                        <label
                          class="custom-control custom-checkbox mb-0 d-inline-block"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="checkAllDataTables"
                            v-model="selectedAll"
                            @click="selectAll"
                          />
                          <span class="custom-control-label">&nbsp;</span>
                        </label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(student, index) in students.data"
                      :key="index"
                      v-if="students.data.length"
                    >
                      <td class="align-middle text-center">
                        <p>{{ students.from + index }}</p>
                      </td>
                      <td class="align-middle text-center">
                        <img
                          width="70"
                          height="70"
                          :src="
                            student.image
                              ? $store.state.base_url + student.image
                              : image
                          "
                          alt=""
                        />
                      </td>
                      <td>
                        <p class="mb-0">
                          <b>{{ student.name }}</b>
                        </p>
                        <p class="mb-0" v-if="student.number">
                          <b>Number : </b>{{ student.number }}
                        </p>
                        <p class="mb-0" v-if="student.email">
                          <b>Email : </b>{{ student.email }}
                        </p>
                      </td>
                      <td class="align-middle text-center">
                        <p class="mb-0" v-if="student.verification_token">
                          <b>OTP : </b>{{ student.verification_token }}
                        </p>
                        <p class="mb-0">
                          <span
                            class="badge"
                            style="margin: 5px"
                            :class="
                              student.is_verified == true
                                ? 'badge-success'
                                : 'badge-warning'
                            "
                          >
                            {{
                              student.is_verified == true
                                ? "Verified"
                                : "Pending Verified"
                            }}
                          </span>
                          <span
                            class="badge"
                            :class="
                              student.status == true
                                ? 'badge-success'
                                : 'badge-warning'
                            "
                          >
                            {{ student.status == true ? "Active" : "Inactive" }}
                          </span>
                        </p>
                      </td>
                      <td class="align-middle">
                        <p class="mb-0">
                          <b>Created At : </b
                          >{{ student.created_at | date_time_format }}
                        </p>
                        <p class="mb-0">
                          <b>Updated At : </b
                          >{{ student.updated_at | date_time_format }}
                        </p>
                      </td>
                      <td class="align-middle">
                        <b-form-checkbox
                          v-model="select.data"
                          :value="student.id"
                          name="flavour-3a"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted"
                      >Showing {{ students.from ? students.from : 0 }} to
                      {{ students.to ? students.to : 0 }} of
                      {{ students.total ? students.total : 0 }}
                      entries</span
                    >
                  </b-col>

                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <pagination
                      :data="students"
                      @pagination-change-page="getResults"
                      align="right"
                      :show-disabled="true"
                      :limit="5"
                    ></pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <b-card title="Import student list">
          <b-card-text>
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group label="File" label-for="file">
                      <b-form-file
                        v-model="form.file"
                        id="file"
                        accept=".xlsx, .csv"
                        placeholder="Choose a file ..."
                      />
                    </b-form-group>
                  </div>
                </div>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="importStudentList"
                >
                  Create
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";

export default {
  data() {
    return {
      selected: [],
      form: new Form({
        file: null,
      }),
      batch_id: null,
      batchs: {},
      search: {
        is_verified: null,
        course_id: null,
        from_date: "",
        to_date: "",
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      students: {},
      image: this.$store.state.base_url + "images/student/default.svg",

      select: {
        data: [],
        selected: false,
      },
      selectedAll: false,
    };
  },
  mounted() {
    this.$store.dispatch("getActiveCourses");
    this.getResults();
    this.getAllBatch();
  },
  methods: {
    async getAllBatch() {
      const batches = await this.callApi("get", "/app/all/batch");
      if (batches.status == 200) {
        this.batchs = batches.data.batches;
      }
    },

    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const students = await this.callApi(
        "post",
        "/app/student/get?page=" + this.search.page,
        this.search
      );
      if (students.status == 200) {
        this.students = students.data.students;
      }
    },

    close() {
      this.batch_id = null;
      this.form.file = null;
    },

    importStudentList() {
      this.form
        .post("/app/student/list-import")
        .then((res) => {
          this.getResults();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.error);
        });
    },

    Delete() {
      // Swal.fire({
      //   title: "Are you sure?",
      //   text: "You won't to delete it!",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor: "#d33",
      //   confirmButtonText: "Yes, delete it!",
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     this.DeleteAlert("/app/course/categories/delete", this.select);
      //     this.select.data = [];
      //     this.getResults();
      //   }
      // });
    },

    // Select Data for Delete
    selectAll(event) {
      if (event.target.checked == false) {
        this.select.data = [];
      } else {
        this.select.data = [];
        this.students.data.forEach((voucher) => {
          this.select.data.push(voucher.id);
        });
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.student;
    },
    courses() {
      return this.$store.getters.getActiveCourses;
    },
  },
  watch: {
    "search.from_date": function (val) {
      this.SearchData();
    },
    "search.to_date": function (val) {
      this.SearchData();
    },
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
    "select.data": function (selected) {
      this.select.selected = selected.length > 0;
      this.selectedAll = selected.length == this.students.data.length;
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
};
</script>

<style></style>
